<template>
  <div>
    <b-card>
      <b-row>
        <b-col sm="12 ">
          <!-- PERMISSION TABLE -->
          <b-col cols="5">
            <b-form-group label-cols="4" label-cols-lg="2" label-size="sm" label="Parameter" label-for="input-sm">
              <v-select v-model="parameter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="option" class="select-size-sm" />
            </b-form-group>
          </b-col>
          <b-card no-body class="border">
            <!-- <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="FolderIcon" size="14" />
                <span class="align-middle ml-50 text-dark h6">Selectbox</span>
              </b-card-title>
            </b-card-header> -->
            <!-- Card1 -->
            <b-row class="p-1">
              <b-col cols="4">
                <b-form-group label-cols="3" label-cols-lg="3" label-size="sm" label="Tanaman" label-for="input-sm">
                  <b-form-input id="s" v-model="nama_tanaman" size="md" placeholder="Tanaman" />
                </b-form-group>
              </b-col>

              <b-col cols="4">
                <b-form-group label-cols="3" label-cols-lg="3" label-size="sm" label="Latin" label-for="input-sm">
                  <b-form-input id="input-md" v-model="nama_latin" size="md" placeholder="Latin" />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label-cols="3" label-cols-lg="3" label-size="sm" label="Satus" label-for="input-sm">
                  <v-select v-model="t_status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="value" :options="t_status_opt" class="select-size-sm" :reduce="(item) => item.id" />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button v-if="btnSimpan" @click="simpanTanaman" variant="success" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'"> Simpan </b-button>
                <b-button v-if="btnUpdate" @click="updateTanaman" variant="warning" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'"> Update </b-button>
                <b-button v-if="btnUpdate" @click="cancelTanaman" variant="danger" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'"> Batal </b-button>
              </b-col>
              <div class="table-responsive text-nowrap">
                <b-table responsive hover class="mb-0" :items="items">
                  <template #cell(status)="data">
                    <label v-if="data.item.status == 1">Aktif</label>
                    <label v-if="data.item.status == 2">Non-Aktif</label>
                  </template>
                  <template #cell(tools)="data">
                    <b-button-group>
                      <b-button variant="success" class="btn-icon rounded-circle" size="sm" @click="onActionEdit(data.item.tools)">
                        <edit-2-icon size="1x" />
                      </b-button>
                      <b-button variant="danger" class="btn-icon rounded-circle" size="sm" @click="onActionDelete(data.item.tools)">
                        <trash-icon size="1x" />
                      </b-button>
                    </b-button-group>
                  </template>
                  status
                </b-table>
              </div>
            </b-row>
          </b-card>
          <!-- Action Buttons -->
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BCardHeader, BCardTitle, BFormSelect, BTable } from "bootstrap-vue";
import vSelect from "vue-select";
import { TrashIcon, Edit2Icon } from "vue-feather-icons";

import Helper from "@/api/helper";
import axios from "axios";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormSelect,
    vSelect,
    BTable,
    TrashIcon,
    Edit2Icon,
  },

  data() {
    return {
      parameter: { title: "Golongan A(Tanaman Makanan Utama)", id: "golA" },
      option: [
        { title: "Golongan A(Tanaman Makanan Utama)", id: "golA" },
        { title: "Golongan B(Tanaman Buah-buahan Yang Menghasilkan Sepanjang Tahun)", id: "golB" },
        { title: "Golongan C(Tanaman Buah-buahan Yang Menghasilkan Menurut Musim)", id: "golC" },
      ],

      // table
      items: [],
      btnSimpan: false,
      btnUpdate: false,
      t_status: "",
      t_status_opt: [
        { id: 1, value: "Aktif" },
        { id: 2, value: "Non-Aktif" },
      ],
      data_arr: [],
      nama_tanaman: "",
      nama_latin: "",
      list_all_tanaman: {},
      id_tanaman: "",
      status_data: "insert",
      arr_nama_tanaman: [],
    };
  },
  methods: {
    onActionEdit(id) {
      let data = this.list_all_tanaman[id];
      this.btnSimpan = false;
      this.btnUpdate = true;
      this.nama_tanaman = data.nama_tanaman;
      this.nama_latin = data.nama_latin;
      this.id_tanaman = id;
      this.status_data = "update";
      this.t_status = data.status;
    },
    cancelTanaman() {
      this.btnSimpan = true;
      this.btnUpdate = false;
      this.nama_tanaman = "";
      this.nama_latin = "";
      this.id_tanaman = "";
      this.status_data = "insert";
      this.t_status = 1;
    },
    onActionDelete(id) {
      const url = Helper.getBaseUrl() + "/toolbox/" + id;
      this.$swal({
        title: "Apakah Anda Yakin?",
        text: "Menghapus Tanaman!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .delete(url, Helper.getConfig())
            .then((response) => {
              this.$swal({
                title: "Hapus Sukses",
                text: "Tanamann Berhasil Di Hapus",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
              this.getSelectBox();
            })
            .catch(function (error) {
              this.$swal({
                title: "Hapus ERROR!",
                text: "Terjadi Kesalahan!",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              });
            });
        } else if (result.dismiss === "cancel") {
          return;
        }
      });
    },
    getSelectBox() {
      this.list_all_tanaman = {};
      this.status_data = "insert";
      this.items = [];
      this.btnSimpan = true;
      this.btnUpdate = false;
      this.t_status = 1;
      this.nama_tanaman = "";
      this.nama_latin = "";
      this.id_tanaman = "";
      this.arr_nama_tanaman = [];
      let l_param = this.parameter.id;
      let url = Helper.getBaseUrl() + "@search?type_name=SettingToolbox&sb_form_type=Fenologi&sb_attribut=" + l_param;
      axios
        .get(url, Helper.getConfig())
        .then((response) => {
          var data = response.data.items;
          for (var i in data) {
            var list = {
              nama_tanaman: data[i].sb_value,
              nama_latin: data[i].sb_text,
              status: data[i].sb_is_active,
              tools: data[i].sb_id,
            };
            this.arr_nama_tanaman.push(data[i].sb_value.toLowerCase());
            this.items.push(list);
            this.list_all_tanaman[data[i].sb_id] = list;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.items = [];
    },

    simpanTanaman() {
      if (this.nama_tanaman == "" || this.nama_latin == "") {
        this.$swal({
          title: "ERROR!",
          text: "Nama Tanaman dan Nama Latin Tidak Boleh Kosong",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }

      const status = this.t_status;
      // console.log("status : ",status,status.id);
      let nama = this.nama_tanaman.trim();
      let latin = this.nama_latin.trim();
      let idx = this.arr_nama_tanaman.indexOf(nama.toLowerCase());
      if (idx != -1) {
        this.$swal({
          title: "ERROR!",
          text: "Nama Tanaman Sudah ada",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }
      const post_url = Helper.getBaseUrl() + "/toolbox";
      const post_data = {
        "@type": "SettingToolbox",
        sb_attribut: this.parameter.id,
        sb_label: nama + "(<i>" + latin + "</i>)",
        sb_value: nama,
        sb_text: latin,
        sb_form_type: "Fenologi",
        sb_is_active: this.t_status,
      };
      axios
        .post(post_url, post_data, Helper.getConfig())
        .then((response) => {
          console.log(response);
          this.$swal({
            title: "Input Data Berhasil!",
            text: "Data Telah berhasil di Simpan",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.resetForm();
          this.getSelectBox();
        })
        .catch((error) => {
          console.log(error);
          error_handler(this, error, "Nama Tanaman", "Input Data Error");
        });
    },
    updateTanaman() {
      const post_url = Helper.getBaseUrl() + "/toolbox/" + this.id_tanaman;
      let nama = this.nama_tanaman.trim();
      let latin = this.nama_latin.trim();

      const post_data = {
        sb_label: nama + "(<i>" + latin + "</i>)",
        sb_value: nama,
        sb_text: latin,
        sb_form_type: "Fenologi",
        sb_is_active: this.t_status,
      };
      axios
        .patch(post_url, post_data, Helper.getConfig())
        .then((response) => {
          console.log(response);
          this.$swal({
            title: "Update data sukses!",
            text: "Data Telah berhasil di Update",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.resetForm();
          this.getSelectBox();
        })
        .catch((error) => {
          console.log(error);
          error_handler(this, error, "Nama Tanaman", "Input Data Error");
        });
    },
    resetForm() {
      this.nama_tanaman = "";
      this.nama_latin = "";
      this.btnSimpan = true;
      this.btnUpdate = false;
    },
  },
  watch: {
    parameter(val) {
      if (val != undefined || val != null) {
        this.getSelectBox();
      }
    },
  },
  mounted() {
    this.getSelectBox();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
