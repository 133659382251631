<template>
  <div>
    <b-card>
      <!-- <b-tabs  -->
      <b-tabs>
        <hr />
        <!-- <b-tab title="SINOP"><sinop /></b-tab>
        <b-tab title="METAR/SPECI"> <metar /></b-tab>
        <b-tab title="FDIH"> <fdih /> </b-tab> -->
        <b-tab title="Fenologi"> <pias /> </b-tab>
        <b-tab title="Kualitas Udara"> <ku /> </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from "bootstrap-vue";
import Sinop from "./Comselectbox/Selectboxsinop.vue";
import Metar from "./Comselectbox/Selectboxmetarspeci.vue";
import Fdih from "./Comselectbox/Selectboxsfdih.vue";
import Pias from "./Comselectbox/Selectboxpias.vue";
import Ku from "./Comselectbox/Selectboxku.vue";
export default {
  components: {
    BTabs,
    BTab,
    BCard,
    sinop: Sinop,
    metar: Metar,
    fdih: Fdih,
    pias: Pias,
    ku : Ku,
  },
};
</script>
