<template>
  <div>
    <b-card>
      <b-row>
        <b-col sm="9">
          <!-- PERMISSION TABLE -->
          <b-col cols="6">
            <b-form-group label-cols="4" label-cols-lg="2" label-size="sm" label="Atribut" label-for="input-sm">
              <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="option" class="select-size-sm" />
            </b-form-group>
          </b-col>
          <b-card no-body class="border">
            <!-- <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="FolderIcon" size="14" />
                <span class="align-middle ml-50 text-dark h6">Selectbox</span>
              </b-card-title>
            </b-card-header> -->
            <!-- Card1 -->
            <b-row class="p-1">
              <b-col cols="3">
                <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Value" label-for="input-sm">
                  <b-form-input id="input-sm" size="sm" placeholder="Id" />
                </b-form-group>
              </b-col>

              <b-col cols="3">
                <b-form-group label-cols="4" label-cols-lg="3" label-size="sm" label="Text" label-for="input-sm">
                  <b-form-input id="input-sm" size="sm" placeholder="Desc" />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label-cols="4" label-cols-lg="3" label-size="sm" label="Satus" label-for="input-sm">
                  <b-form-select v-model="selected" :options="options" size="sm" />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button variant="success" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                  Simpan
                </b-button>
              </b-col>
              <div class="table-responsive text-nowrap">
                <b-table responsive hover class="mb-0" :items="items">
                  <template #cell(action)="data">
                    <b-button-group>
                      <b-button variant="danger" class="btn-icon rounded-circle" size="sm" @click="onActionDelete(data.item.id)">
                        <trash-icon size="1x" />
                      </b-button>
                      <b-button variant="success" class="btn-icon rounded-circle" size="sm" @click="onActionEdit(data.item.id)">
                        <edit-2-icon size="1x" />
                      </b-button>
                    </b-button-group>
                  </template>
                </b-table>
              </div>
            </b-row>
          </b-card>
          <!-- Action Buttons -->
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BCardHeader, BCardTitle, BFormSelect, BTable } from "bootstrap-vue";
import vSelect from "vue-select";
import { TrashIcon, Edit2Icon } from "vue-feather-icons";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormSelect,
    vSelect,
    BTable,
    TrashIcon,
    Edit2Icon,
  },

  data() {
    return {
      selected: { title: "Square" },
      option: [{ title: "Square" }, { title: "Rectangle" }, { title: "Rombo" }, { title: "Romboid" }],

      // table
      items: [
        {
          Value: "01",
          Text: "1. Peramatan cuaca ketika hujan",
          Action: "",
        },
        {
          Value: "02",
          Text: "1. Keadaan cuaca saat peramatan",
          Action: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
