<template>
  <div>
    <b-card>
      <b-row>
        <b-col sm="12 ">
          <!-- PERMISSION TABLE -->
          <b-col cols="5">
            <b-form-group label-cols="4" label-cols-lg="2" label-size="sm" label="Parameter" label-for="input-sm">
              <v-select v-model="parameter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="option" class="select-size-sm" />
            </b-form-group>
          </b-col>
          <b-card no-body class="border">
            <!-- <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="FolderIcon" size="14" />
                <span class="align-middle ml-50 text-dark h6">Selectbox</span>
              </b-card-title>
            </b-card-header> -->
            <!-- Card1 -->
            <b-row class="p-1">
              <b-col cols="4">
                <b-form-group label-cols="3" label-cols-lg="3" label-size="sm" label="Label" label-for="input-sm">
                  <b-form-input id="s" v-model="nama" size="md" placeholder="Label" />
                </b-form-group>
              </b-col>

              <b-col cols="4">
                <b-form-group label-cols="3" label-cols-lg="3" label-size="sm" label="Value" label-for="input-sm">
                  <b-form-input id="input-md" v-model="nama_text" size="md" placeholder="Value" />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label-cols="3" label-cols-lg="3" label-size="sm" label="Satus" label-for="input-sm">
                  <v-select v-model="t_status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="value" :options="t_status_opt" class="select-size-sm" :reduce="(item) => item.id"  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button v-if="btnSimpan" @click="simpanTanaman"  variant="success" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                  Submit
                </b-button>
                <b-button v-if="btnUpdate" @click="updateTanaman" variant="warning" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                  Update
                </b-button>
                <b-button v-if="btnUpdate" @click="cancelTanaman" variant="danger" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                  Batal
                </b-button>
              </b-col>
              <div class="table-responsive text-nowrap">
                <b-table responsive hover class="mb-0" :items="items">
                  <template #cell(status)="data">
                    <label v-if="data.item.status==1">Aktif</label>
                    <label v-if="data.item.status==2">Non-Aktif</label>
                  </template>
                  <template #cell(tools)="data">
                    <b-button-group>
                      
                      <b-button variant="success" class="btn-icon rounded-circle" size="sm" @click="onActionEdit(data.item.tools)">
                        <edit-2-icon size="1x" />
                      </b-button>
                      <b-button variant="danger" class="btn-icon rounded-circle" size="sm" @click="onActionDelete(data.item.tools)">
                        <trash-icon size="1x" />
                      </b-button>
                    </b-button-group>
                  </template>
                  status
                  
                </b-table>
              </div>
            </b-row>
          </b-card>
          <!-- Action Buttons -->
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BCardHeader, BCardTitle, BFormSelect, BTable } from "bootstrap-vue";
import vSelect from "vue-select";
import { TrashIcon, Edit2Icon } from "vue-feather-icons";

import Helper from "@/api/helper";
import axios from "axios";


export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormSelect,
    vSelect,
    BTable,
    TrashIcon,
    Edit2Icon,
  },

  data() {
    return {
      parameter: { title: "Catatan Lab",id : "CL" },
      option: [
        
        { title: "Catatan Lab",id : "CL" }, 
        { title: "Kondisi Cuaca Awal",id : "KCL" }, 
        { title: "Kondisi Cuaca Akhir",id : "KCR" }, 
        { title: "Kejadian Luar Biasa",id : "KLB" }, 
        // { title: "Kondisi Sampling",id : "KSG" }, 
        { title: "Kondisi Sampling",id : "KSL" },
        // { title: "Kondisi Sampel",id : "KSL" },        
        { title: "Kualitas Sampel",id : "KSM" }, 
        { title: "Flag",id : "FLG" }, 
        { title: "Penyelia Lab",id : "PLB" }, 
        ],

      // table
      items: [],
      btnSimpan : false,
      btnUpdate : false,
      t_status : "",
      t_status_opt : [
        { id:1, value:"Aktif" },
        { id:2, value:"Non-Aktif" },
      ],
      data_arr : [],
      nama : "",
      nama_text : "",
      list_all_label : {},
      id_n : "",
      status_data : "insert",
      arr_nama : [],
    };
  },
  methods:{
    onActionEdit(id){
      let data = this.list_all_label[id];
      this.btnSimpan = false;
      this.btnUpdate = true;
      this.nama = data.label;
      this.nama_text = data.value;
      this.id_n = id;
      this.status_data = "update"
      this.t_status = data.status
    },
    cancelTanaman(){
      this.btnSimpan = true;
      this.btnUpdate = false;
      this.nama = "";
      this.nama_text = "";
      this.id_n = "";
      this.status_data = "insert"
      this.t_status = 1
    },
    onActionDelete(id){
      const url = Helper.getBaseUrl() +"/toolbox/"+id;
      this.$swal({
              title: "Apakah Anda Yakin?",
              text: "Menghapus Label!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Delete",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                 axios.delete(url,Helper.getConfig())
                    .then(response => {
                         this.$swal({
                            title: "Hapus Sukses",
                            text: "Label Berhasil Di Hapus",
                            icon: "success",
                            customClass: {
                              confirmButton: "btn btn-success",
                            },
                            buttonsStyling: false,
                          });
                          this.getSelectBox();
                    })
                    .catch(function (error) {
                        this.$swal({
                          title: "Hapus ERROR!",
                          text: "Terjadi Kesalahan!",
                          icon: "error",
                          customClass: {
                            confirmButton: "btn btn-danger",
                          },
                          buttonsStyling: false,
                        });
                    })
              } else if (result.dismiss === "cancel") {;
                
                return;
              }
            });
    },
    async getSelectBox(){
      this.list_all_label = {};
      this.status_data = "insert";
      this.items=[];
      this.btnSimpan = true;
      this.btnUpdate = false;
      this.t_status =1;
      this.nama = "";
      this.nama_text = "";
      this.id_n = "";
      this.arr_nama = [];
      let l_param = this.parameter.id;
      let url = Helper.getBaseUrl()+"@search?type_name=SettingToolbox&sb_form_type=Ku&sb_attribut="+l_param
      await axios
      .get(url,Helper.getConfig())
      .then(response => {
        var data = response.data.items
        for(var i in data){
          var list = {         
            label: data[i].sb_text,
            value: data[i].sb_value,
            status : data[i].sb_is_active,
            tools:data[i].sb_id
          }
          this.arr_nama.push(data[i].sb_value.toLowerCase());
          this.items.push(list);
          this.list_all_label[data[i].sb_id] = list
        }
        // console.log()
        
          
      })
      .catch(error => {
        console.log(error)
      })
      var item_ku = this.items;
        // console.log("item_ku : ",item_ku)
        var item_sort = item_ku.sort((a, b) => {
          let fa = a.label;
          let fb = b.label;

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        })
        this.items = item_sort;
      // this.items=[]
    },

    simpanTanaman(){
      if(this.nama == "" || this.nama_text ==""){
        this.$swal({
            title: "ERROR!",
            text: "Label dan Value Tidak Boleh Kosong",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        return
      }

      const status = this.t_status;
      // console.log("status : ",status,status.id);
      let label = this.nama.trim()
      let value = this.nama_text.trim()
      let idx = this.arr_nama.indexOf(value.toLowerCase())
      if(idx != -1){
        this.$swal({
            title: "ERROR!",
            text: "Value Sudah ada",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        return
      }
      const post_url = Helper.getBaseUrl() +"/toolbox";
      const post_data = {
                "@type": "SettingToolbox",
                "sb_attribut": this.parameter.id,
                "sb_label": label,
                "sb_value": value,
                "sb_text": label,
                "sb_form_type": "Ku",
                "sb_is_active": this.t_status
            }
      axios
        .post(post_url, post_data, Helper.getConfig())
        .then((response) => {
          console.log(response);
          this.$swal({
            title: "Simpan data sukses!",
            text: "Data Telah berhasil di Simpan",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.resetForm();
          this.getSelectBox();
        })
        .catch((error) => {
          console.log(error);
          error_handler(this, error, "Nama Label", "Input Data Error");
        });
    },
    updateTanaman(){
      const post_url = Helper.getBaseUrl() +"/toolbox/"+this.id_n;
      let label = this.nama.trim()
      let value = this.nama_text.trim()
      
      const post_data = {
                "sb_label": label,
                "sb_value": value,
                "sb_text": label,
                "sb_form_type": "Ku",
                "sb_is_active": this.t_status
            }
      axios
        .patch(post_url, post_data, Helper.getConfig())
        .then((response) => {
          console.log(response);
          this.$swal({
            title: "Update data sukses!",
            text: "Data Telah berhasil di Update",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.resetForm();
          this.getSelectBox();
        })
        .catch((error) => {
          console.log(error);
          error_handler(this, error, "Nama Label", "Input Data Error");
        });
    },
    resetForm(){
      this.nama = "";
      this.nama_text = "";
      this.btnSimpan = true;
      this.btnUpdate = false
    },
  },
  watch:{
    parameter(val){
      if(val != undefined || val != null){
        this.getSelectBox();
      }

    },
  },
  mounted(){
    this.getSelectBox();
  },

};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
